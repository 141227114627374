import * as React from 'react'

import { AuthRegister } from 'shared/pages'

import Layout from '../../layouts/landing'

const SITE_NAME = process.env.GATSBY_SITE_NAME

const Register = () => (
  <Layout>
    <AuthRegister
      title={`Regístrate en ${SITE_NAME}`}
      description={'Regístrate para acceder y empezar a trabajar con Leiva y Cia. Contadores.'}
      allowBusiness
    />
  </Layout>
)

export default Register
